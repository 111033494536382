import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Container from 'components/Container';

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <Container>
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
);

NotFoundPage.propTypes = {
  location: PropTypes.object,
};

export default NotFoundPage;
